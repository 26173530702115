p.divider {
    text-align: center !important;
    font-size: '1.33em';
}

.topic {
    background-color: #1a69a4 !important;
    color: #FFFFFF;
}

.topic h3 {
    color: #FFFFFF !important;
}