.site-header {
    font-size: 48px !important;
    font-weight: normal !important;
    margin-bottom: 0px;
    margin-top: 2em !important;
}

.site-sub-header {
    font-size: 1.7em !important;
    margin-top: 0px !important;
    padding-bottom: 80px !important;
}

@media only screen and (max-width: 600px) {
    .site-header {
        font-size: 2em !important;
        margin-top: 1.5em !important;
    }

    .site-sub-header {
        font-size: 1.5em !important;
        margin-top: 0.5em !important; 
    }
}